import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import BeerImage from "../../static/Acapulco/Logo.jpg"
import BeerImage1 from "../../static/Acapulco/production.png"
import BeerImage2 from "../../static/Acapulco/desing.jpg"

import GraphImage from "../../static/Acapulco/graphProduct.png"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  },
  media: {
    width: "100%"
  }
}))

export default function Template({ data }) {
  const classes = useStyles()
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet title={`CFL | ${post.frontmatter.title}`} />
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} pt={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>{post.frontmatter.title}</b>
            </Typography>
            <Grid container justify="center">
              <Grid item xs={11} md={5}>
                <img className={classes.media} src={BeerImage} alt="Acapulco" />
              </Grid>
            </Grid>
          </Box>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <Grid container justify="space-around">
            <Grid container item xs={12} md={4} alignItems="center">
              <img className={classes.media} src={GraphImage} alt="BeerImage" />
            </Grid>
            <Grid item xs={12} md={7} mx={2}>
              <Typography variant="h2" component="h2" gutterBottom>
                <b>{post.frontmatter.descriptionTitle}</b>
              </Typography>
              <Typography variant="body" component="body" gutterBottom>
                {post.frontmatter.description}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="h2" component="h2" gutterBottom align="center">
              <b>{post.frontmatter.imagesTitle}</b>
            </Typography>
            <Grid container justify="space-around">
              <Grid item xs={11} md={5}>
                <img
                  className={classes.media}
                  src={BeerImage1}
                  alt="BeerImage1"
                />
              </Grid>
              <Grid item xs={11} md={5}>
                <img
                  className={classes.media}
                  src={BeerImage2}
                  alt="BeerImage2"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        imagesTitle
        descriptionTitle
      }
    }
  }
`
